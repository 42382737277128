<template>
    <div>
        <input type="file" ref="fileInput" @change="onLeave" v-show="false" />
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Pedidos" :items="breadcrumbs">
                <template v-slot:actions>
                    <v-btn
                        :disabled="isLoading"
                        elevation="2"
                        large
                        class="elevation-0 mt-2 ml-2"
                        @click="confirmationDialog = true"
                        v-if="getUser.user_type === 1"
                    >
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </BreadcrumbsComponent>
        </v-row>

        <v-row v-if="isLoading">
            <v-col md="12" class="text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
        </v-row>

        <v-row v-else>
            <template>
                <v-col cols="12" md="9">
                    <v-tabs v-model="tabs" class="mb-8">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab class="primary--text"> Status </v-tab>

                        <v-tab class="primary--text"> Resumo do pedido </v-tab>
                    </v-tabs>




                    <v-dialog
                        v-model="dialogDeleteMessage"
                        transition="dialog-top-transition"
                        max-width="600"
                        persistent
                    >
                        <v-card class="pa-5" v-if="selectedDelete">
                            <v-card-text class="pt-4">
                                <p
                                    class="text-center mb-4"
                                    style="line-height: 1"
                                >
                                    Tem certeza que deseja excluir esse comentário?
                                    
                                </p>

                                <v-btn
                                    :loading="isLoadingDeleteMessage"
                                    :disabled="isLoadingDeleteMessage"
                                    elevation="2"
                                    large
                                    block
                                    text
                                    class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                    @click="onConfirmDelete"
                                >Sim, excluir</v-btn
                                >
                                <v-btn
                                    :disabled="isLoadingDeleteMessage"
                                    large
                                    block
                                    text
                                    class="mt-3"
                                    @click="dialogDeleteMessage = false"
                                >Cancelar</v-btn
                                >
                            </v-card-text>
                        </v-card>
                    </v-dialog>




                    <v-dialog
                        v-model="dialogEditMessage"
                        transition="dialog-top-transition"
                        max-width="600"
                        persistent
                    >
                        <v-card class="pa-5" v-if="selectedEdit">
                            <v-card-text class="pt-4">
                                
                                <div class="oldMessage">
                                    <v-list-item
                                    >
                                        <v-list-item-avatar>
                                            <v-img :src="getImage(selectedEdit.user)" v-if="getImage(selectedEdit.user)"></v-img>

                                            <v-avatar
                                                color="brown"
                                                v-else
                                            >
                                                <span class="white--text text-h5">{{ getIniciais(selectedEdit.user.username) }}</span>
                                            </v-avatar>

                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title
                                                class="itemlisttitle"
                                            >{{ getTypeUser(selectedEdit.user.type) }}</v-list-item-title
                                            >
                                            <p class="itemlistname">
                                                {{
                                                selectedEdit.user.username
                                                }}
                                            </p>
                                            <p
                                                class="listitemdescription"
                                                v-html="selectedEdit.description"
                                            >
                                            </p>

                                            <v-list-item-subtitle
                                                style="
                                                                margin-top: 10px;
                                                                color: rgba(
                                                                    189,
                                                                    189,
                                                                    189,
                                                                    1
                                                                );
                                                                width: 100%;
                                                                display: flex;
                                                                justify-content: space-between;
                                                            "
                                            >
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                                
                                <div id="contentEditMessage">
                                    <p>Editar mensagem</p>

                                    <vue-editor
                                        v-model="messageEdited"
                                        placeholder="Digite aqui sua mensagem"
                                    />
                                </div>
                                
                                <div class="d-flex justify-space-between">
                                    <v-btn
                                        :loading="isLoadingEditMessage"
                                        :disabled="isLoadingEditMessage"
                                        elevation="2"
                                        large
                                        text
                                        class="white--text grey darken-3 mt-5 elevation-0"
                                        @click="onConfirmEdit"
                                    >Salvar</v-btn
                                    >
                                    <v-btn
                                        :disabled="isLoadingEditMessage"
                                        large
                                        text
                                        class="mt-5"
                                        @click="dialogEditMessage = false"
                                    >Cancelar</v-btn>
                                    
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                    
                    


                    <template v-if="tabs == 0">

                        <v-dialog
                            v-model="confirmationDialogApprove"
                            transition="dialog-top-transition"
                            max-width="600"
                            persistent
                        >
                            <v-card class="pa-5" v-if="budgetSelected">
                                <v-card-text class="pt-4" style="display: inline-grid;">
                                    <p
                                        class="text-center mb-4"
                                        style="line-height: 1"
                                    >
                                        Tem certeza que deseja aprovar a proposta de  <br />
                                        <b>R$ {{ _format(budgetSelected.total) }}</b> da empresa {{ budgetSelected.installer.trading_name }}
                                    </p>

                                    <v-btn
                                        :loading="isLoadingApprove"
                                        :disabled="isLoadingApprove"
                                        elevation="2"
                                        large
                                        text
                                        class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                        @click="onUpdateApprove"
                                        style="width: 216px;background-color: #0A85FF !important;"
                                    >Sim, aprovar</v-btn
                                    >
                                    <v-btn
                                        :disabled="isLoadingApprove"
                                        large
                                        text
                                        class="mt-3 mx-auto"
                                        @click="confirmationDialogApprove = false"
                                        style="width: 216px;"
                                    >Cancelar</v-btn
                                    >
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        
                        
                        <ExpandComponent
                            @onExpand="handlerExpand"
                            v-bind="expand"
                            borderColor="#6FCF97"
                            :totalMessages="0"
                            :totalAttachments="order.budget_installers.length"
                        >
                            <template v-slot:header>
                                <span class="titleExpand">Orçamento</span>
                            </template>

                            <template v-slot:content>
                                <v-divider class="mb-6"></v-divider>
                                <div
                                    v-if="installersOrder.length > 0"
                                >
                                    <v-data-table
                                        hide-default-footer
                                        :items="installersOrder"
                                        :headers="headers"
                                        :item-class="checkAccepted"
                                    >

                                        <template v-slot:item.kwh="{ item }">
                                            <template v-if="item.id">
                                                {{ item.kwh }}kWh
                                            </template>
                                        </template>

                                        <template  v-slot:item.total="{ item }">
                                            <template v-if="item.id">
                                                <v-btn 
                                                    text 
                                                    @click="onDownloadFileBudgetInstaller(item)"
                                                    :disabled="isLoadingDownloadFileBudget"
                                                    :loading="isLoadingDownloadFileBudget"
                                                >
                                                    <v-icon style="color: #004d99">mdi-tray-arrow-down</v-icon> <span style="color: #0a85ff">R$ {{ _format(item.total) }}</span>
                                                </v-btn>
                                            </template>
                                        </template>

                                        <template  v-slot:item.file="{ item }">
                                            <template v-if="item.id">
                                                <template v-if="order.budget_installer">
                                                    
                                                    <v-chip v-if="order.budget_installer.installer_id == item.installer_id">
                                                        Aprovada
                                                    </v-chip>
                                                    
                                                </template>
                                                <template v-else>
                                                    <v-btn rounded text outlined @click="onShowApprove(item)">
                                                        Aprovar <v-icon>mdi-arrow-right</v-icon></v-btn>
                                                </template>
                                            
                                            </template>
                                            
                                            
                                            <template v-else-if="!order.installer_id">
                                                <v-btn v-if="item.installer && item.installer.budget && item.installer.budget.rejected_at == null" rounded text outlined @click="$router.push(`/orders/view/${order.id}/create-budget/${item.installer.id}`)" class="no-uppercase" style="color: #004D99;">
                                                    Adicionar proposta <img class="ml-2" src="/images/icon-shape.svg"></v-btn>

                                                <v-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on">Recusada pela empresa <v-icon style="font-size: 18px; margin-left: 10px;">mdi-help-circle-outline</v-icon></v-chip>
                                                    </template>
                                                    <span v-if="item.installer && item.installer.budget && item.installer.budget.reason_rejected != null">{{item.installer.budget.reason_rejected}}</span>
                                                    <span v-else>Motivo não informado</span>
                                                </v-tooltip>
                                            </template>
                                        </template>
                                    </v-data-table>
                                </div>
                                <p v-else>Nenhum orçamento inserido</p>
                            </template>
                        </ExpandComponent>

                        <template v-for="(step, iStep) in order.steps">
                            <ExpandComponent
                                @onExpand="handlerExpand"
                                v-bind="expand"
                                :totalMessages="step.posts.length"
                                :totalAttachments="getTotalFiles(step.posts)"
                                :borderColor="
                                    order.step_id == step.id
                                        ? '#2F80ED'
                                        : '#6FCF97'
                                "
                                :disabled="
                                    checkDisabled(step.order, order.step_id)
                                "
                            >
                                <template v-slot:header>
                                    <span class="titleExpand">{{
                                        step.title
                                    }}</span>
                                </template>

                                <template v-slot:content>
                                    <v-divider></v-divider>
                                    <div id="contentPosts" ref="contentPosts">
                                        <v-list>
                                            <template
                                                v-for="(
                                                    item, index
                                                ) in step.posts"
                                            >

                                                <v-list-item
                                                    :key="index"
                                                    class="itemlist"
                                                    v-if="item.deleted_at"
                                                >
                                                    <v-list-item-avatar>
                                                        <v-img :src="getImage(item.user)" v-if="getImage(item.user)"></v-img>

                                                        <v-avatar
                                                            color="brown"
                                                            v-else
                                                        >
                                                            <span class="white--text text-h5">{{ getIniciais(item.user.username) }}</span>
                                                        </v-avatar>

                                                    </v-list-item-avatar>


                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            class="itemlisttitle"
                                                        >{{ getTypeUser(item.user.type) }}</v-list-item-title
                                                        >
                                                        <p class="itemlistname">
                                                            {{
                                                            item.user.username
                                                            }}
                                                        </p>

                                                        <v-list-item-subtitle
                                                            style="
                                                                margin-top: 10px;
                                                                color: rgba(
                                                                    189,
                                                                    189,
                                                                    189,
                                                                    1
                                                                );
                                                                width: 100%;
                                                                display: flex;
                                                                justify-content: space-between;
                                                            "
                                                        >
                                                            <span>
                                                              
                                                            </span>

                                                            <span
                                                                style="margin-right: 10px"
                                                            >
                                                                Mensagem excluída em  {{ __dayjs(item.deleted_at).format("DD/MM/YYYY HH:mm")}}
                                                            </span>

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                
                                                <v-list-item
                                                    :key="index"
                                                    class="itemlist"
                                                    v-else
                                                >
                                                    <v-list-item-avatar>
                                                        <v-img :src="getImage(item.user)" v-if="getImage(item.user)"></v-img>

                                                        <v-avatar
                                                            color="brown"
                                                            v-else
                                                        >
                                                            <span class="white--text text-h5">{{ getIniciais(item.user.username) }}</span>
                                                        </v-avatar>
                                                       
                                                    </v-list-item-avatar>

                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            class="itemlisttitle"
                                                            >{{ getTypeUser(item.user.type) }}</v-list-item-title
                                                        >
                                                        <p class="itemlistname">
                                                            {{
                                                                item.user.username
                                                            }}
                                                        </p>
                                                        <p
                                                            class="listitemdescription"
                                                            v-html="item.description"
                                                        >
                                                        </p>

                                                        <p v-if="item.file">
                                                            <v-chip
                                                                v-if="!item.deleted_at"
                                                                class="ma-2 chipfile"
                                                                outlined
                                                                @click="onShowFileOrderPosts(item)
                                                                "
                                                                :disabled="isLoadingFile"
                                                            >
                                                                <v-icon left>
                                                                    mdi-tray-arrow-down
                                                                </v-icon>
                                                                {{
                                                                    item.file.split(
                                                                        "/"
                                                                    )[2]
                                                                }}
                                                            </v-chip>
                                                        </p>

                                                        <v-list-item-subtitle
                                                            style="
                                                                margin-top: 10px;
                                                                color: rgba(
                                                                    189,
                                                                    189,
                                                                    189,
                                                                    1
                                                                );
                                                                width: 100%;
                                                                display: flex;
                                                                justify-content: space-between;
                                                            "
                                                            >
                                                            <span v-if="item.created_at == item.updated_at" style="height: 16px">
                                                                {{
                                                                    __dayjs(item.created_at).format("DD/MM/YYYY HH:mm")
                                                                }}
                                                            </span>
                                                            
                                                            <span v-else style="height: 16px">
                                                                Mensagem editada em {{ __dayjs(item.updated_at).format("DD/MM/YYYY HH:mm") }}
                                                            </span>
                                                            
                                                            <span 
                                                                style="margin-right: 10px"
                                                                v-if="checkUserDelete(item)"
                                                                class="contentControlsMessage"
                                                            >
                                                                <img 
                                                                    src="/images/icon-edit.svg" 
                                                                    class="mr-5 cursor-pointer"
                                                                    @click="onShowEdit(item, index, iStep)"
                                                                >
                                                                
                                                                <img 
                                                                    src="/images/icon-trash.svg" 
                                                                    class="cursor-pointer"
                                                                    @click="onShowDelete(item, index, iStep)"
                                                                >
                                                            </span>
                                                        
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </template>
                                        </v-list>
                                    </div>

                                    <v-row
                                        v-if="order.step_id == step.id && !order.finished"
                                        class="d-flex justify-space-between mb-6 mt-6"
                                        id="contentMessagePost"
                                    >
                                        <v-col cols="10">
                                            <vue-editor
                                                v-model="messagePost"
                                                :editor-toolbar="customToolbar"
                                                placeholder="Digite aqui sua mensagem"
                                            />    
                                        </v-col>
                                        
                                        
                                        
                                        <v-col cols="2" class="d-flex align-content-end justify-end">
                                            <v-btn
                                                fab
                                                small
                                                id="btnFile"
                                                @click="
                                                        $refs[
                                                            'fileInput'
                                                        ].click()
                                                    "
                                            >
                                                <v-icon>
                                                    mdi-attachment
                                                </v-icon>
                                            </v-btn>

                                            <v-btn
                                                fab
                                                class="ml-2"
                                                color="primary"
                                                @click="onSendPost(step.id, iStep)"
                                                :loading="isLoadingSend"
                                                :disabled="isLoadingSend || (!messagePost && !fileSelected)"
                                            >
                                                <v-icon> mdi-send </v-icon>
                                            </v-btn>
                                        
                                        </v-col>
                                        
                                    </v-row>

                                    <div v-if="fileSelected">
                                        <v-chip class="ma-2 chipfile" outlined>
                                            {{ fileSelected }}
                                        </v-chip>
                                        <v-btn
                                            fab
                                            small
                                            outlined
                                            color="#e0e0e0"
                                            @click="clearInput"
                                            icon
                                        >
                                            <v-icon
                                                >mdi-trash-can-outline</v-icon
                                            >
                                        </v-btn>
                                    </div>
                                </template>
                            </ExpandComponent>
                            
                            
                            
                            
                            
                                <div

                                    class="d-flex justify-space-between mb-6 mt-6"
                                    v-if="step.id == order.step_id && order.budget_installer && !order.finished"
                                >

                                    <div 
                                        style="width: 100%" 
                                        class="d-flex text-center justify-center align-content-center" 
                                        v-if="order.is_after_sales"
                                    >
                                        <v-chip
                                            outlined
                                            color="#219653"
                                            large
                                        >
                                            <img src="/images/icon-calendar.svg" class="mr-2">Pós-venda termina em {{ order.after_sales_days }} dia(s)</v-chip>
                                    </div>

                                    <template v-else>
                                    <v-btn
                                        rounded
                                        :disabled="isLoadingStep || !isNotFirstStep(order.step_id)"
                                        :loading="isLoadingStep"
                                        @click="onSendPreviousStep"
                                    ><v-icon>mdi-arrow-left</v-icon>
                                        Retroceder</v-btn
                                    >

                                    <v-btn
                                        v-if="order.last_step"
                                        rounded
                                        color="#219653"
                                        dark
                                        @click="onSendFinishOrder"
                                        :disabled="isLoadingStep"
                                        :loading="isLoadingStep"
                                    >
                                        Finalizar pedido
                                    </v-btn>

                                    <v-btn
                                        rounded
                                        color="#219653"
                                        style="color: #FFF !important"
                                        @click="onSendNextStep"
                                        :disabled="isLoadingStep"
                                        :loading="isLoadingStep"
                                        v-else
                                    >
                                        Avançar para a próxima etapa
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                    </template>
                                </div>
                                
                            
                            
                        </template>
                    </template>

                    <OrderSummary
                        v-if="tabs == 1"
                        :items="orderSummary"
                        :order="order"
                        @onClick="handlerOrderSummaryClick"
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <DetailsPanelComponent
                        title="VER TAMBÉM"
                        :items="details"
                        @onClick="clickPanel"
                    />
                    <div class="mt-8">
                        <DetailsPanelComponent
                            title="OUTRAS AÇÕES"
                            :items="others"
                        />
                    </div>
                </v-col>

                <v-dialog
                    v-model="confirmationDialog"
                    transition="dialog-top-transition"
                    max-width="600"
                    persistent
                >
                    <v-card class="pa-5" v-if="order">
                        <v-card-text class="pt-4">
                            <h1 class="text-center mb-4">
                                Deseja excluir este pedido?
                            </h1>
                            <span class="d-block text-center mt-4 mb-5"
                                >Importante: essa ação não poderá ser
                                desfeita.</span
                            >

                            <div class="mt-5 mb-5">
                                <span class="d-block font-weight-medium"
                                    >Pedido</span
                                >
                                <v-divider></v-divider>
                                <div class="mb-3 mt-4">
                                    <span class="d-block">ID: {{ order.id }}</span>
                                    <span class="d-block"
                                    ><strong>CLIENTE:</strong>
                                {{
                                    order.budget && order.budget.client ? order.budget.client.name : "-"
                                }}</span
                                    >
                                    <span class="d-block"
                                    ><strong>CONSULTOR:</strong>
                                {{
                                    order.budget && order.budget.consultant
                                        ? order.budget.consultant.name
                                        : "-"
                                }}</span
                                    >
                                </div>
                            </div>

                            <v-btn
                                :disabled="isLoadingDelete"
                                :loading="isLoadingDelete"
                                elevation="2"
                                large
                                block
                                text
                                class="white--text grey darken-3 mt-5 elevation-0 mx-auto"
                                @click="onDelete"
                            >
                                Sim, confirmo a exclusão
                            </v-btn>
                            <v-btn
                                :disabled="isLoadingDelete"
                                large
                                block
                                text
                                class="mt-3"
                                @click="confirmationDialog = false"
                                >Cancelar</v-btn
                            >
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import ExpandComponent from "@/components/ExpandComponent/Index";
import DetailsPanelComponent from "@/components/DetailsPanelComponent/Index";
import OrderSummary from "@/pages/orders/OrderSummary";
import dayjs from "dayjs";
import {formatMoney} from "../../utils/formatMoney";
import {getResidenceType} from "../../utils/residenceType";
import {getRoofType} from '../../utils/roofType'
import {getPowerInput} from '../../utils/powerInput'

export default {
    components: {
        BreadcrumbsComponent,
        ExpandComponent,
        DetailsPanelComponent,
        OrderSummary,
    },
    data: () => {
        return {
            isLoadingSend: false,
            isMobile: window.innerWidth < 800,
            messagePost: "",
            fileSelected: null,
            filePost: null,
            expand: {
                initOpen: false,
                showBorder: false,
                borderColor: "red",
                totalAttachments: 4,
                totalMessages: 5,
            },
            tabs: 0,
            confirmationDialog: false,
            title: "MAIS DESSE PEDIDO",
            color: "#1E88E5",
            borderColor: "#1E88E5",
            headers: [
                {
                    text: 'Empresa solar',
                    align: 'start',
                    sortable: false,
                    value: 'installer.trading_name',
                },
                {
                    text: 'Geração',
                    align: 'start',
                    sortable: false,
                    value: 'kwh',
                },
                {
                    text: 'Proposta',
                    align: 'start',
                    sortable: false,
                    value: 'total',
                },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'file',
                },
            ],
            details: [],
            others: [],
            value: true,
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Pedidos",
                },
                {
                    text: "Visualizar",
                    disabled: true,
                },
            ],
            order: null,
            id: null,
            isLoading: true,
            isLoadingDelete: false,
            orderSummary: [
                {
                    title: "ATUALIZADO EM",
                    value: "updated_at",
                },
                {
                    title: "ID",
                    value: "custom_id",
                },
                {
                    title: "INSTALADOR",
                    value: "installer.company_name",
                },
                {
                    title: "CONSULTOR",
                    value: "budget.consultant.name",
                },
                {
                    title: "CLIENTE",
                    value: "budget.client.name",
                },
                {
                    title: "ORÇAMENTO APROVADO",
                    value: "budget_installer.total",
                },
                {
                    title: "INVESTIMETNO ESTIMADO NA SIMULAÇÃO",
                    value: "budget.simulator.budget_total",
                },
                {
                    title: "ENDEREÇO DA INSTALAÇÃO",
                    value: "budget.simulator.address",
                },
                {
                    title: "ÁREA DISPONÍVEL",
                    value: "budget.area_size",
                },
                {
                    title: "TIPO DE TELHADO",
                    value: "budget.simulator.roof_type",
                },
                {
                    title: "TIPO DE CONEXÃO",
                    value: "budget.simulator.power_input",
                },
                {
                    title: "TECNOLOGIA DO INVERSOR",
                    value: "inversor",
                },
                {
                    title: "ANEXOS COM LINKS PARA DOWNLOAD",
                    value: "budget.documents",
                },
                {
                    title: "OBSERVAÇÕES",
                    value: "budget.notes",
                },
            ],
            isLoadingStep: false,
            isLoadingFile: false,
            confirmationDialogApprove: false,
            budgetSelected: null,
            isLoadingApprove: false,
            isLoadingDownloadFileBudget: false,
            customToolbar: [
                
            ],

            selectedDelete: null,
            dialogDeleteMessage: false,
            isLoadingDeleteMessage: false,
            selectedDeleteIndex: null,
            selectedDeleteStepIndex: null,
            dialogEditMessage: false,
            selectedEdit: null,
            messageEdited: null,
            isLoadingEditMessage: false,

            installersOrder: []
        };
    },
    computed: {
        ...mapGetters(["getUser"]),
    },
    methods: {
        ...mapActions([
            "showOrder",
            "showFileOrderPosts",
            "deleteOrder",
            "insertPostStep",
            "nextStepOrder",
            "previousStepOrder",
            "selectInstallerBudget",
            "getDocumentBudgetInstaller",
            "sendFinishOrder",
            "sendDeleteMessagePost",
            "sendEditMessagePost"
        ]),

        clickPanel (item) {
            if (item.href) {
                this.$router.push(item.href)
            }
        },

        getTotalFiles (posts) {
            let total = 0
            
            for (let p of posts) {
                if (p.file) total++
            }
            
            return total
        },

        checkUserDelete (item) {
            if (this.getUser.user_type == 1) {
                return true
            } else if (this.getUser.id == item.user_id) {
                return true
            }
            return false
        },

        async onShowDelete (item, index, indexStep) {
            this.dialogDeleteMessage = true
            this.selectedDelete = item
        },

        async onShowEdit (item, index, indexStep) {
            this.dialogEditMessage = true
            this.selectedEdit = item
            this.messageEdited = item.description
        },
        
        
        async onConfirmDelete () {
            this.isLoadingDeleteMessage = true
            const r = await this.sendDeleteMessagePost(this.selectedDelete.id)
            if (r) {
                this.selectedDelete.deleted_at = dayjs().format()
                this.$eventHub.$emit('snackBar', {color: 'success', message: 'Mensagem excluída'})
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao apagar mensagem'})
            }
            this.isLoadingDeleteMessage = false
            this.dialogDeleteMessage = false
        },


        async onConfirmEdit () {
            this.isLoadingEditMessage = true
            
            const data = {
                id: this.selectedEdit.id,
                description: this.messageEdited
            }
            
            const r = await this.sendEditMessagePost(data)
            if (r) {
                this.selectedEdit.description = this.messageEdited
                this.selectedEdit.updated_at = dayjs().format()
                this.$eventHub.$emit('snackBar', {color: 'success', message: 'Mensagem alterada'})
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao editar mensagem'})
            }
            this.isLoadingEditMessage = false
            this.dialogEditMessage = false
        },



        isNotFirstStep (step_id) {
            
            let firstArray = null
            
            for (let step of this.order.steps) {
                
                if (firstArray) {
                    if (firstArray.ordem > step.ordem) {
                        firstArray = step
                    }
                } else {
                    firstArray = step
                }
            }
            
            
            if (firstArray && firstArray.id != step_id) {
                return true
            }
            
            return false
        },
        

        async onSendFinishOrder () {
            this.isLoadingStep = true
            const r = await this.sendFinishOrder(this.order.id)
            if (r) {
                this.$eventHub.$emit('snackBar', {color: 'success', message: 'Pedido atualizado com sucesso'})
                this.order.step_id = r.step_id
                this.order.finished = r.finished

                this.changeStep(this.order.steps[this.getStepIndexById(r.step_id)])
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao atualizar pedido'})
            }

            this.isLoadingStep = false
        },
        
        onShowApprove (item) {
            this.budgetSelected = item
            this.confirmationDialogApprove = true
        },


        async onUpdateApprove () {
            if (this.budgetSelected) {

                this.isLoadingApprove = true

                const r = await this.selectInstallerBudget(this.budgetSelected.id)

                if (r) {
                    this.$eventHub.$emit('snackBar', {color: 'success', message: 'Empresa selecionada com sucesso'})
                    this.$router.push('/orders')
                } else {
                    this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao selecionar empresa'})
                }

                this.isLoadingApprove = false                
                
            } else {
                this.budgetSelected = null
                this.confirmationDialogApprove = false
            }
        },

        async onDownloadFileBudgetInstaller (item) {
            this.isLoadingDownloadFileBudget = true
            const r = await this.getDocumentBudgetInstaller(item.id);
            if (r) {
                this.downloadBase64File('', r.file, r.name)
            } else {
                this.$eventHub.$emit('snackBar', {color: 'error', message: 'Erro ao baixar arquivo'})
            }
            this.isLoadingDownloadFileBudget = false
        },


        getTypeUser (type) {
            if (type == 1) {
                return 'Administrador'
            } else if (type == 2) {
                return 'Consultor'
            } else if (type == 3) {
                return 'Cliente'
            } else if (type == 4) {
                return 'Empresa solar'
            } else {
                return '-'
            }
        },


        getImage (user) {
            if (user.installer) {
                return user.installer.url
            } else if(user.consultant) {
                return user.consultant.url
            }
            
            return null
        },

        getIniciais(nameString) {
            try {
                const fullName = nameString.split(" ");
                const initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                return initials.toUpperCase();
            } catch (e) {
                if (nameString) {
                    return nameString.substring(0, 2).toUpperCase();
                } else {
                    return ""
                }
            }
        },

        
        
        clearInput() {
            this.$refs["fileInput"].value = null;
            this.fileSelected = null;
            this.filePost = null;
        },
        async onDelete() {
            this.isLoadingDelete = true;

            try {
                await this.deleteOrder({id: this.id});
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedido apagado com sucesso",
                });
                this.$router.push("/orders");
            } catch (error) {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao apagar pedido",
                });
            }

            this.isLoadingDelete = false;
        },
        handlerExpand(event) {
            console.log(event);
        },
        handlerOrderSummaryClick(event) {
            console.log(event);
        },
        __dayjs(date) {
            return dayjs(date);
        },

        async onSendPreviousStep() {
            this.isLoadingStep = true;
            const r = await this.previousStepOrder(this.order.id);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedido atualizado com sucesso",
                });
                this.order.step_id = r.step_id;
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao atualizar pedido",
                });
            }

            this.isLoadingStep = false;
        },

        async onSendNextStep() {
            this.isLoadingStep = true;
            const r = await this.nextStepOrder(this.order.id);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Pedido atualizado com sucesso",
                });
                this.order.step_id = r.step_id;
                this.order.is_after_sales = r.is_after_sales
                this.order.after_sales_days = r.after_sales_days
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao atualizar pedido",
                });
            }

            this.isLoadingStep = false;
        },
        downloadBase64File(contentType, base64Data, fileName) {
            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        async onShowFileOrderPosts(item) {
            this.isLoadingFile = true;
            const r = await this.showFileOrderPosts(item.id);
            if (r) {
                this.downloadBase64File("", r.file, r.name);
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao baixar arquivo",
                });
            }
            this.isLoadingFile = false;
        },

        async onSendPost(step_id, index_step) {
            
            this.isLoadingSend = true
            const post = {
                description: this.messagePost,
                step_id: step_id,
                order_id: this.order.id,
                file: this.filePost,
            };

            const r = await this.insertPostStep(post);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Post inserido com sucesso",
                });
                this.messagePost = null;
                this.order.steps[index_step].posts.push(r);
                this.filePost = null;
                this.clearInput();
                const el = this.$refs.contentPosts[index_step];

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element

                    setTimeout(() => {
                        el.scrollTop = el.scrollHeight;
                    }, 100);
                }
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Erro ao inserir post",
                });
            }

            this.isLoadingSend = false
        },

        checkDisabled(ordem, step_id) {
            
            if (!this.order.budget_installer) {
                return true
            }
            
            let orderAtual = null;
            for (let step of this.order.steps) {
                if (step_id == step.id) {
                    orderAtual = step.order;
                }
            }

            if (ordem > orderAtual) {
                return true;
            }

            return false;
        },
        async onLeave(e) {
            this.fileSelected = e.target.files[0].name;
            this.filePost = await this.toBase64(e.target.files[0]);

            console.log(this.filePost);
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        _format(value)
        {
            return formatMoney(value)
        },
        __getResidenceType (type) {
            return getResidenceType(type)
        },
        
        _getRoofType (type) {
            return getRoofType(type)
        },
        
        _getPowerInput (type) {
            return getPowerInput(type)
        },

        checkAccepted (item) {
            console.log(item);
            if(this.order.budget_installer){
                if(this.order.budget_installer.installer_id != item.installer_id){
                    return "budget-refused";
                }
            }
            /*if (this.$route.query.recentUpdated) {
                if (item.id == this.$route.query.recentUpdated) {
                    return "recent-updated"
                }
            }*/
        }
    },
    async mounted() {
        this.id = this.$route.params.id;
        this.isLoading = true;
        try {
            this.order = await this.showOrder(this.$route.params.id);
            
            if (this.order.budget_installer) {
                this.order.budget_installer.total = "R$ " + this._format(this.order.budget_installer.total)
            }
            
            
            let installersOrder = JSON.parse(JSON.stringify(this.order.budget_installers))
            
            
            for (let installer of this.order.installers) {
                
                let exists = false
                
                for (let i in installersOrder) {
                    if (installersOrder[i].installer_id == installer.id) {
                        exists = true
                        
                        break
                    }
                }
                
                if (!exists) {
                    installersOrder.push({
                        kwh: null,
                        file: null,
                        total: null,
                        installer
                    })
                }
            }
            
            
            
            this.installersOrder = installersOrder
            
            
            
            if (this.order && this.order.budget && this.order.budget.simulator) {
                this.order.budget.simulator.budget_total = "R$ " + this._format(this.order.budget.simulator.budget_total)
                this.order.budget.simulator.roof_type = this._getRoofType(this.order.budget.simulator.roof_type)
                this.order.budget.simulator.power_input = this._getPowerInput(this.order.budget.simulator.power_input)
                
                this.order.inversor = this.order.inversor.map(i => i.name).join(', ')
                
            }
            
            
            this.details = [
                {
                    name: "Perfil do cliente",
                    data: "perfil do cliente",
                    href: "/clients/view/" + this.order.budget.client_id
                },
                {
                    name: "Perfil do instalador",
                    data: "perfil do instalador",
                    href: this.order.installer_id ? "/installers/view/" + this.order.installer_id : null
                },
                {
                    name: "Simulação deste pedido",
                    data: "simulação deste pedido",
                    href: "/simulations/" + this.order.budget.simulator_id
                },
            ]
            
            this.others = [
                {
                    name: "Cancelar pedido",
                    data: "cancelar pedido",
                },
                {
                    name: "Adiar pedido",
                    data: "adiar pedido",
                },
            ]
            
        } catch (error) {
            console.log('AAA', error)
            this.$eventHub.$emit("snackBar", {
                color: "error",
                message: "Erro ao recuperar pedido",
            });
        }

        this.isLoading = false;

        window.addEventListener(
            "resize",
            () => (this.isMobile = window.innerWidth < 800)
        );
    },
    destroyed() {
        //window.removeEventListener("resize")
    },
};
</script>

<style>

    .itemlist:hover .contentControlsMessage {
        display: block !important;
    }
    
    .contentControlsMessage {
        display: none;
    }
    
    .oldMessage {
        padding: 10px;
        background-color: rgba(242, 242, 242, 1);
    }

  

    #contentEditMessage .quillWrapper .ql-snow.ql-toolbar {
        display: none !important;
    }


    #contentEditMessage .quillWrapper {
        width: 100%;
        height: 100px;
    }

    #contentEditMessage .ql-container.ql-snow {
        border: 1px solid #cacaca !important;
    }
    
    
    .ql-editor {
        min-height: 100px !important;
    }
    
    
    
    
    
    #contentMessagePost .quillWrapper .ql-snow.ql-toolbar {
        display: none !important;
    }
    
    
    #contentMessagePost .quillWrapper {
        width: 100%;
        height: 100px;
    }
    
    #contentMessagePost .ql-container.ql-snow {
        border: 0px !important;
    }
    
    
#contentPosts {
    max-height: 300px;
    overflow-y: auto;
}

#contentMessagePost #btnFile {
    margin-top: 5px !important;
}

.itemlist {
    padding: 20px 0px;
}

.itemlisttitle {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(189, 189, 189, 1);
}

.itemlistname {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 10px;
}

.listitemdescription {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 10px;
}

.v-tooltip__content{
    width: 360px !important;
    padding: 12px 20px !important;
    background-color: #002A53 !important;
    left: 880px !important;
}

.budget-refused{
    opacity: 0.5;
}
</style>
