import Http from '@/plugins/Http'

export default {
    async login({ commit }, credentials) 
    {
        try {
            let { data } = await Http.post('/login', credentials)
            if (data.status) {
                commit('SET_USER', {id: data.id, name: data.name, email: data.email, user_type: data.user_type})
                commit('SET_TOKEN', `${data.type} ${data.token}`)
                return true
            }
        } catch (e) {
            return false
        }
    },

    logout({commit}) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        commit('SET_USER', {
            name:'',
            email:'',
            consultant_id:null
        })
        commit('SET_TOKEN', null)
    },

    async sendRestorePassword ({ commit }, credentials)
    {
        try {
            await Http.post('/sendRestorePassword', credentials)
            return true
        } catch (e) {
            return false
        }
    },

    async restorePassword ({ commit }, credentials)
    {
        try {
            await Http.post('/restorePassword', credentials)
            return true
        } catch (e) {
            return false
        }
    },
    
    async getStates () {
        try {
            let { data } = await Http.get('/state')
            return data;
        } catch (e) {
            return []
        }
    },
    async cityOrState ( { commit }, val ) {

        try {
            const { data } = await Http.get('/cityOrState', { params: { query: val } })
            
            return data
        } catch (e) {
            console.log(e)
            return { count:0, entries:[] }
        }
    },

    async getCities ( { commit }, val ) {
        try {
            const {data} = await Http.get('/city', {params: {query: val}})
            return data
        } catch (e) {
            return false

        }
    },

    async checkTokenReset ( { commit }, token ) {
        try {
            await Http.get('/checkTokenReset/' + token)
            return true
        } catch (e) {
            return false

        }
    },
    
    
    async myNotifications () {
        try {
            const { data } = await Http.get('/my-notifications')
            return data
        } catch (e) {
            return []
        } 
    },


    async dashboardCounters () {
        try {
            const { data } = await Http.get('/dashboard/counters')
            return data
        } catch (e) {
            return []
        }
    },

    async dashboardEvolutionWeek () {
        try {
            const { data } = await Http.get('/dashboard/evolution-week')
            return data
        } catch (e) {
            return []
        }
    },


    async getPlans ({}) {
        try {
            const { data } = await Http.get('/plans')

            return data
        } catch (e) {
            return false
        }
    },


}