<template>
    <v-row no-gutters class="d-flex justify-center">
        <v-col md="6">
            <v-card elevation="0">
                <v-card-title>Faça seu login</v-card-title>

                <v-card-text>
                    <form @keydown.enter="onLogin">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-tabs v-model="tabs" fixed-tabs>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab class="primary--text">
                                        Administrador
                                    </v-tab>

                                    <v-tab class="primary--text">
                                        Consultor
                                    </v-tab>
                                </v-tabs>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="E-mail"
                                    outlined
                                    v-model="user.email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    :append-icon="
                                        showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :type="showPassword ? 'text' : 'password'"
                                    name="input-10-2"
                                    label="Senha"
                                    outlined
                                    @click:append="showPassword = !showPassword"
                                    v-model="user.password"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    color="blue-grey"
                                    class="white--text"
                                    @click="onLogin"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    >Entrar</v-btn
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    large
                                    block
                                    text
                                    @click="$router.push('/esqueci-senha')"
                                    >Esqueci minha senha</v-btn
                                >
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",
    data: () => {
        return {
            showPassword: false,
            tabs: null,
            user: {
                email: "",
                password: "",
            },
            isLoading: false,
        };
    },
    methods: {
        ...mapActions(["login"]),

        async onLogin() {
            this.isLoading = true;
            const user = Object.assign(this.user);
            user.type = this.tabs + 1;
            const r = await this.login(user);
            if (r) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Login efetuado com sucesso",
                });
                
                if (user.type == 2) {
                    this.$router.push("/activities");
                } else {
                    this.$router.push("/");
                }
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "Usuário ou senha inválido",
                });
            }
            this.isLoading = false;
        },
    },
};
</script>
