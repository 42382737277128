<template>
    <div>
        <v-row no-gutters class="mt-10 mb-8">
            <BreadcrumbsComponent title="Clientes" :items="breadcrumbs" />
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <form @keydown.enter="!isLoading && isValid && onInsertClient()">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Nome"
                                placeholder="Nome"
                                outlined
                                v-model="client.username"
                                :rules='rules.nameRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Email"
                                placeholder="Email"
                                outlined
                                hint="Uma senha automática será gerada e enviada para o e-mail cadastrado."
                                persistent-hint
                                v-model="client.email"
                                :rules='rules.emailRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="DDD Celular"
                                placeholder="DDD Celular"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-model="client.phone"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                :rules='rules.phoneRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-text-field
                                label="CPF/CNPJ"
                                placeholder="CPF/CNPJ"
                                outlined
                                hint="Apenas números"
                                persistent-hint
                                v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                ]"
                                v-model="client.cpf_cnpj"
                                :rules='rules.cpfCnpjRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                            v-if="client.cpf_cnpj.length >= 15"
                        >
                            <v-text-field
                                label="Razão Social"
                                placeholder="Razão Social"
                                outlined
                                v-model="client.corporate_name"
                                :rules='rules.corporateRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-select
                                :items="optionsStates"
                                item-value="id"
                                item-text="name"
                                label="Estado"
                                outlined
                                v-model="client.state_id"
                                :rules='rules.stateRules'
                                @blur="validate" 
                                @focus="clearRules"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-checkbox
                                v-model="client.opt_in"
                                label="Autorizo a Market Solar a entrar em contato por e-mail, celular ou whatsapp"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                elevation="2"
                                large
                                block
                                class="white--text grey darken-3 elevation-0"
                                @click="onInsertClient"
                                :disabled="isLoading || !isValid"
                                :loading="isLoading"
                            >
                                Cadastrar
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn
                                large
                                block
                                text
                                @click="$router.push('/clients')"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent/Index";
import { mapActions } from "vuex";

export default {
    components: {
        BreadcrumbsComponent,
    },
    data: () => {
        return {
            rules: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Clientes",
                },
                {
                    text: "Adicionar novo",
                    disabled: true,
                },
            ],
            client: {
                username: "",
                email: "",
                cpf_cnpj: "",
                phone: "",
                opt_in: false,
                state_id: null,
                corporate_name: null,
            },
            isLoading: false,
            optionsStates: [],
        };
    },
    computed: {
        isValid() {
            if (this.client.cpf_cnpj.length >= 15) {
                if (!this.client.corporate_name) {
                    return false;
                }
            }

            return !!(
                ["username", "cpf_cnpj", "phone", "state_id"].every(
                    (key) => !!this.client[key]
                ) &&
                /^[A-Za-z0-9_\-\.+]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/i.test(
                    this.client.email
                )
            );
        },
    },
    methods: {
        ...mapActions(["getStates", "insertClient"]),

        async onInsertClient() {
            this.isLoading = true;

            this.client.email = this.client.email.trim();

            const resp = await this.insertClient(this.client);

            if (resp) {
                this.$eventHub.$emit("snackBar", {
                    color: "success",
                    message: "Cliente cadastrado com sucesso",
                });
                this.client = {
                    username: "",
                    email: "",
                    cpf_cnpj: "",
                    phone: "",
                    federative_state: "",
                    opt_in: false,
                    state_id: null,
                    corporate_name: null,
                };
            } else {
                this.$eventHub.$emit("snackBar", {
                    color: "error",
                    message: "E-mail já cadastrado",
                });
            }

            this.isLoading = false;
        },

        validate() {
            this.rules = {
                "nameRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "emailRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => /.+@.+/.test(v) || "Insira um e-mail válido"
                ],
                "phoneRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um telefone válido"
                ],
                "cpfCnpjRules": [
                    v => !!v || "O preenchimento deste campo é obrigatório",
                    v => v.length >= 14 || "Insira um CPF/CNPJ válido"
                ],
                "corporateRules": [v => !!v || "O preenchimento deste campo é obrigatório"],
                "stateRules": [v => !!v || "O preenchimento deste campo é obrigatório"]
            }
        },

        clearRules(){
            this.rules = []
        }
    },

    async mounted() {
        const states = await this.getStates();
        this.optionsStates = states;
    },
};
</script>
